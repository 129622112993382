<template>
  <div class="Authenticated">
    <navbar class="Authenticated__navbar" @toggle-nav="toggleNav" />
    <sidebar
      :class="{ visible: nav }"
      class="Authenticated__sidebar"
      @close-nav="nav = false"
    />
    <transition name="fade" mode="out-in">
      <router-view class="Authenticated__body" />
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import Navbar from '@/components/common/navigation/Navbar';
import Sidebar from '@/components/common/navigation/Sidebar';

export default {
  name: 'Authenticated',
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {
      nav: false,
    };
  },
  mounted() {
    Vue.http.interceptors.push((req, next) => {
      next((res) => {
        if (!res.ok && res.status === 401) {
          this.$store.dispatch('logout', true);
        }
        return res;
      });
    });
  },
  methods: {
    toggleNav() {
      this.nav = !this.nav;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/Authenticated.scss';
</style>
